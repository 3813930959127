import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { SelectMark } from 'hudl-uniform-ui-components';
import selectMarkPreviewData from '../../../data/previews/select-mark.yml';
import pageHeroData from '../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sizeSelectMarkState: 'default',
      stateSelectMarkState: 'default',
    };
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Select Mark"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Select Mark" />

        <PageNavigation>
          <PageNavigationLink>Size</PageNavigationLink>
          <PageNavigationLink>State</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Size">
          <Paragraph>
            Select mark is available in small, medium and large. Consider view
            density and the mark’s position when choosing between the three.
          </Paragraph>
          <ComponentPreview
            name="SelectMarkSize"
            layout="default"
            previewData={selectMarkPreviewData.sizes}>
            <SelectMark
              onClick={() =>
                this.setState({
                  sizeSelectMarkState:
                    this.state.sizeSelectMarkState === 'default' ||
                    this.state.sizeSelectMarkState === 'unselected'
                      ? 'selected'
                      : 'unselected',
                })
              }
              selectedState={this.state.sizeSelectMarkState}
            />
          </ComponentPreview>
        </Section>

        <Section title="State">
          <Paragraph>
            The select mark’s state changes with mouse and touch interactions.
          </Paragraph>
          <ComponentPreview name="SelectMarkState" layout="default">
            <SelectMark
              onClick={() =>
                this.setState({
                  stateSelectMarkState:
                    this.state.stateSelectMarkState === 'default' ||
                    this.state.stateSelectMarkState === 'unselected'
                      ? 'selected'
                      : 'unselected',
                })
              }
              selectedState={this.state.stateSelectMarkState}
            />
          </ComponentPreview>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Multi-Selection Mode</SectionSubhead>
          <Paragraph>
            Select mark should only be used with multi-selection mode. It’s a
            prominent indication that multiple items in a group can be selected
            at the same time.
          </Paragraph>
          <Paragraph>
            <img
              src={require('../../../imgs/diagrams/selectmark-multi-action.gif')}
            />
          </Paragraph>
          <Paragraph>
            The primary action should appear when the first item is selected.
            The state change and use of the Action color reinforce the
            relationship between the items and the action.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use it in place of a checkbox group."
              img="selectmark-multi-dont"
            />
            <DontDoItem
              type="do"
              text="use it when multi-select is enabled."
              img="selectmark-multi-do"
            />
          </DontDo>

          <SectionSubhead>Complex Selectable Regions</SectionSubhead>
          <Paragraph>
            Selectable regions sometimes require sub-actions (buttons, links)
            and different states (expansion, activation), making use and design
            more difficult.
          </Paragraph>
          <Paragraph>
            Check our{' '}
            <Link href="/guidelines/content-selection" isDesignCodeLink>
              content selection patterns
            </Link>{' '}
            to see how select mark can be incorporated.
          </Paragraph>
        </Section>

        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
